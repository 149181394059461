import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'

import React from 'react'
import Layout from '../components/Layout'
import img from '../images/voucher.webp'
import {Fade} from 'react-reveal'
import {Seo} from '../components/Seo'

const Voucher = () => {
  return (
    <Layout>
      <Seo title='Voucher | Modelowanie Sylwetki' />

      <div className='page-container'>
        <div className='page voucher'>
          <div className='content-1'>
            <h1>Voucher</h1>

            <div className='section'>
              <div className='image-container'>
                <img src={img} alt='zdjęcie voucheru' width={512} height={640} />
              </div>
              <div className='text'>
                <h2>
                  Spraw GENIALNY, ale przede wszystkim TRAFIONY i UNIKATOWY upominek swoim
                  bliskim
                </h2>
                <p>
                  Voucher to forma eleganckiego bonu upoważniającego do skorzystania z
                  wybranych usług naszego gabinetu. Wartość Voucheru może obejmować albo
                  określoną kwotę do wykorzystania w naszym gabinecie lub wybrany zabieg –
                  pakiet zabiegów. Podaruj unikalny prezent swoim bliskim!
                </p>
                <p>
                  Realizujemy VOUCHERY okolicznościowe - idealnie sprawdzą się na różne
                  okazje:
                </p>
                <ul>
                  <li className='checkmark'>Dzień Mamy</li>
                  <li className='checkmark'>Dzień Dziecka</li>
                  <li className='checkmark'>Urodziny</li>
                  <li className='checkmark'>Święta</li>
                  <li className='checkmark'>Rocznica</li>
                </ul>
                <h3>Zadzwoń do nas:</h3>
                <a href='tel:+48 728 008 928'>
                  <FontAwesomeIcon icon={faPhone} />
                  <p>+48 728 008 928</p>
                </a>
                <h3>Kontakt mailowy:</h3>
                <a href='mailto:studio@modelowaniesylwetki-wawer.pl'>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <p>studio@modelowaniesylwetki-wawer.pl</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Voucher
